import styled, { keyframes, css } from 'styled-components';
import { colors } from '~/styles/settings/colors';

export const ListContainer = styled.div`
  margin-top: 40px;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const TextContent = styled.div`
  h1 {
    font-size: 3.6rem;
    letter-spacing: 1.52px;
    color: #cecece;
    text-transform: uppercase;
  }
  p {
    font-size: 15px;
    color: gray;
    font-weight: 400;
  }
`;

const expandHeight = keyframes`
  from {
    max-height: 0;
  }
  to {
    max-height: 1000px; 
  }
`;

const collapseHeight = keyframes`
  from {
    max-height: 1000px; 
  }
  to {
    max-height: 0;
  }
`;

export const CoProducersContainer = styled.div<{ isVisible: boolean }>`
  overflow: hidden;
  max-height: ${({ isVisible }) => (isVisible ? '1000px' : '0')};

  ${({ isVisible }) =>
    isVisible
      ? css`
          animation: ${expandHeight} 1s ease-out;
        `
      : css`
          animation: ${collapseHeight} 0.5s ease-out forwards;
        `}
`;

export const ButtonContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  color: white;
  min-height: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
  th {
    background-color: gray;
    padding: 10px;
    text-align: left;
  }
  td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  tr:hover {
    background-color: #dddddd;
    td {
      color: black;
    }
  }

  th:last-child,
  td:last-child {
    width: 150px;
  }

  td:last-child {
    min-height: 50px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  th:last-child {
    border-radius: 0px 20px 0px 0px;
  }
  th:first-child {
    border-radius: 20px 0px 0px 0px;
  }
`;

export const ActionButton = styled.button`
  height: 40px;
  width: 40px;
  color: white;
  background-color: gray;
  font-size: 25px;
  border-radius: 20%;

  :hover {
    background-color: #5b5b5b;
  }
`;

export const ModalButtonContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ModalForm = styled.form`
  padding: 20px 150px 25px 150px;
  display: flex;
  flex-direction: column;
`;

export const ConfirmModalConntent = styled.div`
  color: white;
  font-weight: normal;
  padding: 20px 100px 25px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    font-size: 200px;
  }
  h1 {
    font-size: 18px;
    font-weight: bold;
    margin: 20px;
    text-align: center;
  }
  h2 {
    font-size: 16px;
    font-weight: normal;
    text-align: center;
  }

  @media (max-width: 400px) {
    padding: 20px 20px 20px 20px;
    h1 {
      font-size: 15px;
    }
    h2 {
      font-size: 13px;
    }
  }
`;

export const Label = styled.label`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  color: ${colors.lightGrey};
  font-size: 2.1rem;
  font-weight: 500;

  > div {
    border-color: ${colors.lightGrey};
    color: ${colors.lightGrey};
    padding-top: 10px;
    padding-left: 5px;

    > input {
      color: ${colors.lightGrey};
      font-size: 21px;
    }
  }
`;
