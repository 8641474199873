import React, { useState } from 'react';

import { FiTrash } from 'react-icons/fi';
import { GoPencil } from 'react-icons/go';
import { FaRegCheckCircle } from 'react-icons/fa';

import Button from '../Button';
import * as S from './styles';
import Modal from '../Modal';
import Input from '../Input';
import Paginate from '../Paginate';
import { PageOptionsRequest } from '~/models/Common';

export const AssociatesList: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [ConfirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [pageOptions, setPageOptions] = useState<PageOptionsRequest>({
    page: 1,
  });

  const handleModalOpen = (): void => {
    setModalIsOpen(true);
  };

  const handleCreateCoproductor = (): void => {
    setModalIsOpen(false);
    setConfirmModalIsOpen(true);
  };

  return (
    <>
      <S.ListContainer>
        <S.TextContent>
          <h1>COPRODUTORES</h1>
          <p>Chame colaboradores para a sua produção e promoção de cursos</p>
        </S.TextContent>
        <Button
          type="button"
          IsGray
          size="220px"
          onClick={() => setIsVisible(!isVisible)}
        >
          {isVisible ? 'MOSTRAR MENOS' : 'MOSTRAR MAIS'}
        </Button>
      </S.ListContainer>

      <S.CoProducersContainer isVisible={isVisible}>
        <S.ButtonContent>
          <Button type="button" onClick={() => handleModalOpen()}>
            NOVO COPRODUTOR
          </Button>
        </S.ButtonContent>

        <S.Table>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Email</th>
              <th>Telefone</th>
              <th>CPF</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>João Silva</td>
              <td>joao.silva@email.com</td>
              <td>(11) 98765-4321</td>
              <td>123.456.789-00</td>
              <td>
                <S.ActionButton>
                  <GoPencil />
                </S.ActionButton>
                <S.ActionButton>
                  <FiTrash />
                </S.ActionButton>
              </td>
            </tr>

            <tr>
              <td>João Silva</td>
              <td>joao.silva@email.com</td>
              <td>(11) 98765-4321</td>
              <td>123.456.789-00</td>
              <td>
                <S.ActionButton>
                  <GoPencil />
                </S.ActionButton>
                <S.ActionButton>
                  <FiTrash />
                </S.ActionButton>
              </td>
            </tr>

            <tr>
              <td>João Silva</td>
              <td>joao.silva@email.com</td>
              <td>(11) 98765-4321</td>
              <td>123.456.789-00</td>
              <td>
                <S.ActionButton>
                  <GoPencil />
                </S.ActionButton>
                <S.ActionButton>
                  <FiTrash />
                </S.ActionButton>
              </td>
            </tr>
          </tbody>
        </S.Table>

        <Paginate
          {...{ setPageOptions }}
          pageCount={10}
          pageRangeDisplayed={0}
          noScroll
        />
      </S.CoProducersContainer>

      <S.ListContainer>
        <S.TextContent>
          <h1>AFILIADOS</h1>
          <p>
            Tenha afiliados para promover seus cursos através do link exclusivo
            dele. O afiliado irá ganhar uma porcentagem de cada venda.
          </p>
        </S.TextContent>
        <Button type="button" IsGray size="220px">
          MOSTRAR MAIS
        </Button>
      </S.ListContainer>

      <Modal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen}>
        <h1>Novo coprodutor</h1>
        <S.ModalForm>
          <S.Label>
            Nome:
            <Input name="1" />
          </S.Label>

          <S.Label>
            E-mail:
            <Input name="2" />
          </S.Label>

          <S.Label>
            Telefone:
            <Input name="3" />
          </S.Label>

          <S.Label>
            CPF:
            <Input name="4" />
          </S.Label>

          <S.ModalButtonContent>
            {' '}
            <Button
              type="button"
              onClick={() => handleCreateCoproductor()}
              style={{ marginTop: '30px' }}
            >
              CADASTRAR COPRODUTOR
            </Button>
          </S.ModalButtonContent>
        </S.ModalForm>
      </Modal>

      <Modal
        modalIsOpen={ConfirmModalIsOpen}
        setModalIsOpen={setConfirmModalIsOpen}
        withoutCloseButton
      >
        <S.ConfirmModalConntent>
          <FaRegCheckCircle />

          <h1>Coprodutor cadastrado com sucesso!</h1>
          <h2>
            Um e-mail foi enviado para o coprodutor. Para poder receber a
            participação em cada curso, é necessário completar o cadastro
            presente no e-mail.
          </h2>
          <S.ModalButtonContent>
            {' '}
            <Button
              type="button"
              onClick={() => setConfirmModalIsOpen(false)}
              style={{ marginTop: '30px' }}
            >
              CADASTRAR COPRODUTOR
            </Button>
          </S.ModalButtonContent>
        </S.ConfirmModalConntent>
      </Modal>
    </>
  );
};
